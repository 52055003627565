





















































import {Component, Prop} from 'vue-property-decorator';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {Action} from 'vuex-class';
import VcSwitch from '@/components/VCSwitch.vue';
import {timeToFrom, phoneFormat} from '@/utils/helpers';
import {profileStore} from '@/store/modules/profile';
import {mixins} from 'vue-class-component';
import ContainerCalc from '@/components/mixins/ContainerCalc';

@Component({
  name: 'user-profile-general',
  components: {VcSwitch, ContactInfoCard}
})
export default class UserProfileGeneral extends mixins(ContainerCalc) {
  @Prop({type: Object, required: true}) user

  @Action changeAdminRole;

  get associate() {
    return this.user;
  }

  get active() {
    return this.associate?.workingStatus?.type === 1;
  }

  get workingStatus() {
    return this.associate?.workingStatus?.name;
  }

  get statusTimeMessage() {
    const targetDate = this.associate?.workingStatus?.time?.toDate()?.getTime() || 0;
    if (this.active) {
      const diff = timeToFrom(targetDate, null, false)
      return diff ? `More than ${diff}` : 'Just now'
    }
    const duration = this.associate?.workingStatus?.duration || 0;
    const diff = timeToFrom(targetDate + duration, null)
    return diff ? `Absent for ${diff}` : 'Until I turn it back on'
  }

  get admin() {
    return this.associate?.roles?.admin || this.associate?.admin;
  }

  set admin(value) {
    try {
      this.changeAdminRole({id: this.associate!.id, admin: value});
      console.log(`set admin. value: ${value}`);
    } catch (err) {
      console.log(`set admin. error: ${err}`);
    }
  }

  get email() {
    return this.associate?.email;
  }

  set email(email) {
    this.associate!.email = email;
  }

  get position() {
    return this.associate?.position;
  }

  set position(position) {
    this.associate!.position = position;
  }

  get phone() {
    return phoneFormat(this.associate?.phoneNumber);    
  }

  set phone(phone) {
    this.associate!.phoneNumber = phone;
  }

  async onSaveNotificationSettings(period: number = 0) {
    await profileStore.muteProfile(period)
  }

  mounted() {
    this.calcHeight(this.$refs.infoCol)
  }
}
